//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCookieByKey } from '@/utils/cookie'
import { getCategory, getHotGoods, getSwiper } from '@/api/index'
import spIndexFloor from '@/components/spIndexFloor'
export default {
  name: 'Home',
  layout: 'index',
  components: {
    spIndexFloor
  },
  async asyncData () {},
  data () {
    return {
      local: '',
      everyday: {},
      category: [],
      swiper: [],
      title: [],
      swiperIndexOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      },
      maskShow: false,
      swiperAds: [],
      swiperAdsOption: {
        loop: false,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      }
    }
  },
  computed: {},
  mounted() {
    this.local = getCookieByKey('fat_lang', true)
    this.getInitData()
    // if (process.client) {
    //   if (this.swiperAds && this.swiperAds.length) {
    //     this.maskShow = true
    //     // window.localStorage.setItem('maskShow', 'yes')
    //   } else {
    //     this.maskShow = false
    //     // window.localStorage.setItem('maskShow', 'false')
    //   }
    //   // if (maskShow !== 'yes') {
    //   //   // this.maskShow = true
    //   //   window.localStorage.setItem('maskShow', 'yes')
    //   // }
    // }
  },
  methods: {
    getInitData() {
      this.getSwiper()
      this.getSwiperAds()
      this.getSwiperTitle()
      this.getHotGoods()
      this.getCategoryGoods()
    },
    // 轮播图
    getSwiper() {
      getSwiper(this.$axios, {}).then(res => {
        this.swiper = res.data
      })
    },
    getSwiperAds() {
      getSwiper(this.$axios, {id: 6}).then(res => {
        this.swiperAds = res.data
        if (this.swiperAds && this.swiperAds.length) {
          const status = window.localStorage.getItem('maskShowTitle')
          if (status !== '1') {
            this.maskShow = true
          }
        } else {
          this.maskShow = false
        }
      })
    },
    closeMaskShow() {
      window.localStorage.setItem('maskShowTitle', '1')
      this.maskShow = false
    },
    getSwiperTitle() {
      getSwiper(this.$axios, {title: 'PC首页装修'}).then(res => {
        console.log('res getSwiperTitle', res)
        this.title = res.data.map(item => item.image)
      })
    },
    // 获取每日必看
    getHotGoods() {
      getHotGoods(this.$axios, {}).then(res => {
        console.log('hot goods', res)
        this.everyday = res.data
      })
    },
    // 获取分类商品 withbrandgoods = 1
    getCategoryGoods() {
      getCategory(this.$axios, {withbrandgoods: 1}).then(res => {
        this.category = res.data
      })
    },
    // 跳转
    handleSwiper(data) {
      // console.log('data', data)
      if (data.path) {
        window.open(data.path, '_blank')
      }
    },
    // 跳转到详情
    toGoodsDetail(id) {
      const route = this.$router.resolve({
        path: '/goods/detail',
        query: {
          id
        }
      })
      window.open(route.href, '_blank')
    }
  }
}
